import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import { formatNumber } from './global-components';

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatNumber', function (value) {
  if (value !== null && value !== undefined) {
    const number = Number(value);
    if (!isNaN(number)) {
      return number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
  return '0,00';
});

Vue.filter('formatDateTodmY', function (value) {
  if (value !== null && value !== undefined) {
    const new_date = new Date(value);
    if (!isNaN(new_date)) {
      return new_date.toLocaleDateString('de-DE');
    }
  }
  return '';
});

Vue.prototype.$formatNumber = formatNumber;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
