import Vue from 'vue';
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue';

Vue.component(FeatherIcon.name, FeatherIcon);

export function formatNumber(value) {
  if (value !== null && value !== undefined) {
    const number = Number(value);
    if (!isNaN(number)) {
      return number.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
  return '0,00';
}
